import {gql, useMutation, useQuery} from '@apollo/client'
import {CircularProgress, Grid, Link, Typography} from '@mui/material'
import Divider from '@mui/material/Divider'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {Box, Stack, SxProps, styled} from '@mui/system'
import _ from 'lodash'
import {DateTime} from 'luxon'
import React, {useEffect, useRef, useState} from 'react'
import {useHref} from 'react-router-dom'
import {getPlaceholder} from './Directory'
import Footer from './Footer'
import Header from './Header'
import {useMeQuery} from './api/types'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import colors from './colors'
import CardContainerComponent from './components/CardContainer'
import MobileNavMenu from './profile-view/MobileNavMenu'
import CompanyImageIcon from './profile/Profile.CompanyImageIcon'
import {externalLink} from './profile/utils'
import {SecondaryButton} from './ui/Buttons'

export const route = {
    path: '/engagement',
    element: (
      <RequiresAuthentication>
        <EngagemenView />
      </RequiresAuthentication>
    ),

}

const GET_PROFILE_VIEWS = gql`
    query Metrics($filter: String!, $resumeAfter: ID) {
        metrics(filter: $filter, resumeAfter: $resumeAfter) {
            createdAt
            id
            source {
              ... on MinimalProfile {
                id
                owner {
                    id
                }
                profileType
                viewData
              }
            }
            type
            extra
        }
    }
`

const BlurFilter = styled('div')({
  filter: 'blur(3.5px)',
})

const tabs: SxProps = {
    display: {xs: 'none', md: 'block'},
    '& .MuiTabs-indicator': {
      backgroundColor: colors.evergreen[100],
    },
  }

  const tab: SxProps = {
    color: colors.darkEvergreen[40],
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '110%',
    fontStyle: 'normal',
    padding: '0 16px',
    textTransform: 'none',
    '&:visited': {
      color: colors.darkEvergreen[40],
    },
    '&.Mui-selected': {
      color: colors.evergreen[100],
      borderBottom: 0,
    },
  }

  const Separator = styled('hr')(
    ({theme}) => `
        border: 1px solid;
        color: ${colors.background[3]};
        `,
  )

  type TabValues = 'profile_view' | 'profile_interaction'

  function TabBar(props: any) {
    const [value, setValue] = React.useState<TabValues>('profile_view')

    const onChange = (_event: React.SyntheticEvent, newValue: TabValues) => {
      setValue(newValue)
      props.onChange(newValue)
    }




    return (
      <Grid container justifyContent="space-between" item sx={{backgroundColor: colors.background[1],marginTop: '50px', position: props.tabPosition, top: '45px'}}>
        <Grid item>
          <Tabs
            sx={tabs}
            value={value}
            aria-label="engagement filters"
            role="navigation"
            onChange={onChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab
              disabled={false}
              sx={tab}
              value="profile_view"
              label={'Profile views'}
            />
            <Tab
              disabled={false}
              sx={tab}
              value="profile_interaction"
              label={'Profile Clicks'}
            />
          </Tabs>
        </Grid>
        <Grid item xs={0} md={12} sx={{position: 'relative', top: '-14px', zIndex: '-1'}}>
          <Separator />
        </Grid>
      </Grid>
    )
  }

  function ProfileViewMetricItem(props: any) {
    const  { href, profile, sinceString } = props
    const photoKey =  profile?.logo_url
    const imgUrl = photoKey ?? getPlaceholder(profile.profileType, Math.floor(Math.random() * 11))

    return (
      <Box sx={{marginTop: '0.31rem', borderBottom: `1px solid ${colors.background[3]}`, padding: '10px'}}>
        {/* TODO: find a way to block Mui link from being used easily? */}
        <Link href={href} underline='none' >
        {/* <Grid container direction="row"  spacing={1}>
    <Grid item  xs='auto' sx={{width:'55px !important'}}> */}
          <Grid container direction="row" spacing={{md: 2}}>
            <Grid item xs={2} md={1}>
              <CompanyImageIcon src={imgUrl} />
            </Grid>
            <Grid container item direction="column" xs paddingLeft={{xs: '15px', md: '0.5em'}}>
              <Grid item>
                <Typography variant="body3" sx={{color: colors.darkEvergreen[60],fontSize:'12px', lineHeight: '130%'}}>
                  {_.startCase(profile?.profileType)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body3" sx={{color: colors.darkEvergreen[100],fontSize:'16px', lineHeight: '110%'}}>
                  {profile?.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body3" sx={{color: colors.darkEvergreen[100], display: '-webkit-box', fontSize:'14px', letterSpacing: '-0.14px', lineHeight: '130%', overflow: 'hidden', 'webkitLineClamp': '1', 'webkitBoxOrient': 'vertical'}}>
                  {profile?.tagline}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="flex-end" justifyContent="center" item xs={2} spacing={2}>
              <Grid item>
                <Typography variant="body3" sx={{color: colors.darkEvergreen[60],fontSize:'14px', letterSpacing: '-0.14px', lineHeight: 'normal'}}>
                  {sinceString}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </Box>
    )
  }

  function ProfileInteractionMetricItem(props: any) {
    const  { href, metric, profile, sinceString } = props
    const photoKey =  profile?.logo_url
    const imgUrl = photoKey ?? getPlaceholder(profile.profileType, Math.floor(Math.random() * 11))

    const links = metric?.links
    return (
      <Box sx={{marginTop: '0.31rem', borderBottom: `1px solid ${colors.background[3]}`, padding: '10px'}}>
        {/* TODO: find a way to block Mui link from being used easily? */}
        <Link href={href} underline='none' >
        {/* <Grid container direction="row"  spacing={1}>
    <Grid item  xs='auto' sx={{width:'55px !important'}}> */}
          <Grid container direction="row" spacing={{md: 2}}>
            <Grid item xs={2} md={1}>
              <CompanyImageIcon src={imgUrl} />
            </Grid>
            <Grid container item direction="column" xs paddingLeft={{xs: '15px', md: '0.5em'}}>
              <Grid item>
                <Typography variant="body3" sx={{color: colors.darkEvergreen[60],fontSize:'12px', lineHeight: '130%'}}>
                  {_.startCase(profile?.profileType)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body3" sx={{color: colors.darkEvergreen[100],fontSize:'16px', lineHeight: '110%'}}>
                  {profile?.name}
                </Typography>
              </Grid>
              <Grid item sx={{marginTop: '10px'}}>
              {
                links.map((l:any) => {
                  return (<Typography variant="body3" sx={{color: colors.darkEvergreen[60], display: '-webkit-box', fontSize:'14px', letterSpacing: '-0.14px', lineHeight: '130%', overflow: 'hidden', 'webkitLineClamp': '1', 'webkitBoxOrient': 'vertical'}}>
                  Clicked {l}
                </Typography>)
                })
              }
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="flex-end" justifyContent="center" item xs={2} spacing={2}>
              <Grid item>
                <Typography variant="body3" sx={{color: colors.darkEvergreen[60],fontSize:'14px', letterSpacing: '-0.14px', lineHeight: 'normal'}}>
                  {sinceString}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </Box>
    )
  }

  function calculateSinceString(metric: any) {
    const sinceDays = Math.floor(Math.abs(DateTime.fromISO(metric.createdAt).diffNow(['days']).days))
    const sinceWeeks = Math.floor(Math.abs(DateTime.fromISO(metric.createdAt).diffNow(['weeks']).weeks))

    let sinceString = ''

    if (sinceDays < 1){
      sinceString = 'Today'
    }
    else if (sinceDays <= 7) {
      sinceString = `${sinceDays}d`
    } else if (sinceWeeks <= 4) {
      sinceString = `${sinceWeeks}w`
    } else {
      sinceString = DateTime.fromISO(metric.createdAt).toLocaleString()
    }
    return sinceString
  }

  function MetricItem(props: any) {
    const metric = props.metric
    const sinceDays = Math.floor(Math.abs(DateTime.fromISO(metric.createdAt).diffNow(['days']).days))
    const sinceWeeks = Math.floor(Math.abs(DateTime.fromISO(metric.createdAt).diffNow(['weeks']).weeks))

    let sinceString = calculateSinceString(metric)

    let profile = {
        logo_url: metric.source.viewData?.logo_url,
        name: metric.source.viewData?.name,
        pageId: metric.source.owner.id,
        profileType: metric.source.profileType,
        tagline: metric.source.viewData?.tagline,
      }


    const href = useHref(`/profile/${profile.pageId}`)

    switch (metric.type) {
      case 'profile_view':
        return (
          <ProfileViewMetricItem profile={profile} sinceString={sinceString} href={href} />
        )
      case 'profile_interaction':
        return (
          <ProfileInteractionMetricItem profile={profile} sinceString={sinceString} href={href}  metric={metric} />
        )

    }

    return (<></>)
  }

  function SummaryComponent(props: any) {
    // TODO: undo display none after wired up
    return (
        <Box display="none" sx={{backgroundColor: colors.background['warm white'],borderRadius: '30px', boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.10)', padding: '50px 30px', marginBottom: '48px'}}>
          <Stack direction='row' justifyContent='flex-start' spacing={3}>
                <Stack>
                <Typography variant='body2' sx={{color: colors.gray[1]}}>Total Views</Typography>
                <Typography variant='body2' sx={{color: colors.darkEvergreen[100], fontSize: '32px'}}>9</Typography>
                </Stack>
                <Divider orientation='vertical' flexItem />
                <Stack>
                <Typography variant='body2'>Past 30d Views</Typography>
                <Typography variant='body1' sx={{color: colors.evergreen[100], fontSize: '28px'}}>9</Typography>
                </Stack>
                <Stack>
                <Typography variant='body2'>30d Change</Typography>
                <Typography variant='body1' sx={{color: colors.evergreen[100], fontSize: '28px'}}>--</Typography>
                </Stack>

            </Stack>
        </Box>
    )
  }

  function TooltipComponent(props: any) {
    return (
        <Box sx={{background: colors.background[3], borderRadius: '20px', marginBottom: '90px', padding: '25px 50px'}}>
            <Stack direction='row'  alignItems='center'  justifyContent='space-between' >


                    <Stack direction='row'  >
                    <Box sx={{display:'inline-block', fontSize:'16px', paddingRight:'6px'}}>&#x1F4A1;</Box>
                    <Typography sx={{color: colors.gray[1], display:'inline-block' }} variant='body2'>Companies that get the most views have great hero images and well tagged accounts.</Typography>
                    </Stack>
                    <Box >
                        <SecondaryButton
                        sx={{marginLeft:'12px', textDecoration: 'none', width: '125px'}}
                        size="medium"
                        disabled={false}
                        href="https://intercom.help/cherub/en/articles/9035310-cherub-profile-guide-seed-stage-companies#h_2b3107c0f4"
                    >
                        Learn More
                    </SecondaryButton>
                    </Box>

            </Stack>
        </Box>
    )
  }


  function ProfileViewEmptyState(props: any) {
    const {meData, href} = props
    return(<>
      <Typography variant='d2' sx={{color: colors.evergreen[100], textAlign: 'center', fontSize: {md: '64px'}}}>
        No views yet
      </Typography>
      <Typography variant='body2' sx={{color: colors.evergreen[40], textAlign: 'center', paddingBottom: '2rem'}}>
        {meData?.me?.profileType === 'founder' ? (
          <>
            Make sure you have an&nbsp;
            <a color={colors.evergreen[40]} {...externalLink('https://intercom.help/cherub/en/articles/9035310-cherub-profile-guide-seed-stage-companies')}>all-star profile</a>.
          </>
        ) : (
          <>
            Make sure you've added tags so founders can find you.&nbsp;
            <a color={colors.evergreen[40]} href={href}>Edit profile</a>
          </>
        )}
      </Typography>
    </>)
  }

  function ProfileInteractionEmptyState(props: any) {
    const {href} = props
    return(<>
      <Typography variant='d2' sx={{color: colors.evergreen[100], textAlign: 'center', fontSize: {md: '64px'}}}>
        No clicks yet
      </Typography>
      <Typography variant='body2' sx={{color: colors.evergreen[40], textAlign: 'center', paddingBottom: '2rem'}}>
          Make sure you have your links up to date.&nbsp;
          <a color={colors.evergreen[40]} href={href}>Edit profile</a>
      </Typography>
    </>)
  }

  function EmptyStateComponent(props: any) {
    const {emptyState} = props
    switch (emptyState) {
      case 'profile_view':
        return <ProfileViewEmptyState {...props} />
      case 'profile_interaction':
        return <ProfileInteractionEmptyState {...props} />
      default:
          return <ProfileViewEmptyState {...props} />
    }
  }

function Engagement(props: any): React.JSX.Element {
  function updateFilter(tab: TabValues) {
    setFilter(tab)
    loadMoreViews({variables: {filter: tab, resumeAfter: null}})
  }

  function tabChange(tab: TabValues) {
    updateFilter(tab)
    switch (tab) {
      case 'profile_view':
        setMetricTitleMessage('Who viewed your profile')
        break
      case 'profile_interaction':
        setMetricTitleMessage('Who clicked on your profile links')
        break
    }
  }

  const PAGE_SIZE = 20
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false)
  const [metricTitleMessage, setMetricTitleMessage] = useState('Who viewed your profile')
  const [currentViewData, setcurrentViewData] = useState<any[]>([])
  const [me, setMe] = useState<any>({})

  const [filter, setFilter] = useState<TabValues>('profile_view')

  const [ generatePortalUrl ] = useMutation(gql`
    mutation StripePortal {
      stripe {
        portal(input: {}) {
          sessionUrl
        }
      }
    }
  `)

  const onManageSubscription = () => generatePortalUrl().then(({data}) => {
    data.stripe.portal.sessionUrl && setTimeout(() => window.open(data.stripe.portal.sessionUrl, '_blank'))
  })

  const {data: meData} = useMeQuery({
    onCompleted: (data: any) => {
      setMe(data.me)
    },
  })

  function updateData(data: any) {
    const aggregatedData = []
    let dataPointer = 0

    if (data.length > 0) {
      if (data[0].type === 'profile_view') {
        setcurrentViewData(data)
        return
      }
      let d = _.assign({}, data[0])
      d.links = [data[0].extra.link]
      aggregatedData.push(d)
    }

    for (let i = 1; i < data.length; i++) {
      const currentDate = calculateSinceString(aggregatedData[dataPointer])
      const nextDate = calculateSinceString(data[i])

      if (currentDate === nextDate && data[i].source.id === aggregatedData[dataPointer].source.id) {
        //aggregatedData[dataPointer].links = aggregatedData[dataPointer].links || []
        aggregatedData[dataPointer].links.push(data[i].extra.link)
      } else {
        let d = _.assign({}, data[i])
        d.links = [data[i].extra.link]
        aggregatedData.push(d)
        dataPointer++
      }
    }
    data.forEach((metric: any) => {})

    setcurrentViewData(aggregatedData)
  }

  function fetchMoreViews() {
    const lastMetric = _.last(currentViewData)
    // setLoadMoreDisabled(false})
    loadMoreViews({variables: {filter: filter, resumeAfter: lastMetric.id}}).then(({data}) => {
      if (data.metrics.length === 0 || data.metrics.length < PAGE_SIZE) {
        setLoadMoreDisabled(true)
        setShowLoadMore(false)
      }

      updateData([...currentViewData, ...data.metrics])
    })
  }

  const {
    data,
    loading,
    error,
    fetchMore: loadMoreViews,
  } = useQuery(GET_PROFILE_VIEWS, {
    variables: {filter: filter, resumeAfter: null},
    onCompleted: data => {
      if (data.metrics.length >= PAGE_SIZE) {
        setShowLoadMore(true)
        setLoadMoreDisabled(false)
      } else {
        setShowLoadMore(false)
      }
      updateData(data.metrics)
    },
  })

  const emptyState = !loading && !error && currentViewData.length === 0
  const href = useHref(`/profile/${meData?.me?.orgId}/edit`)
  // backout this change until everything is decided
  const showPayWall = false //meData?.me?.accountType === 'basic'
  const VariableContainer = showPayWall ? BlurFilter : styled('div')({})

  const tabbarRef = useRef(null)
  const [tabPosition, setTabPosition] = useState('relative')
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio < 1) {
          setTabPosition('fixed')
        } else {
          setTabPosition('')
        }
      },
      {rootMargin: '-10px', threshold: [0, 0.01, 0.1, 1]},
    )

    if (tabbarRef.current) {
      observer.observe(tabbarRef.current)
    }

    return () => {
      if (tabbarRef.current) {
        observer.unobserve(tabbarRef.current)
      }
    }
  })

  return (
    <Stack justifyContent="flex-start" sx={{ padding: {md: '40px 120px', xs: '20px 25px'}}}>
      <Typography variant="h1" sx={{color: colors.evergreen[100], textAlign: 'left'}} ref={tabbarRef}>
        Engagement
      </Typography>
      <Box sx={{marginTop: '16px', display: {xs: 'block', md: 'none'}}}>
        <MobileNavMenu
          defaultValue={0}
          sections={[{title: 'Profile Views'}, {title: 'Profile Clicks'}]}
          onClick={i => {
            const tabs = ['profile_view', 'profile_interaction'] as TabValues[]
            tabChange(tabs[i])
          }}
        />
      </Box>
      <TabBar onChange={tabChange} tabPosition={tabPosition} />
      {showPayWall &&
        (<div style={{padding: '20px', display: 'flex', justifyContent: 'center'}}>
          <CardContainerComponent onClickButton={onManageSubscription}/>
        </div>) }
        <Stack sx={{padding: {md: '40px 200px'}}}>
          {loading && <CircularProgress />}
          <SummaryComponent />
          {!emptyState && (
            <>
              {me?.profileType === 'founder' && <TooltipComponent />}
              <Typography>{metricTitleMessage}</Typography>
              <div>
                <Separator />
              </div>
            </>
          )}
          <VariableContainer>
              {emptyState && <EmptyStateComponent emptyState={filter} href={href} meData={meData} />}
              {currentViewData && (
                <Stack>
                  {currentViewData.map(metric => {
                    return <MetricItem key={metric.id} metric={metric} />
                  })}
                  {showLoadMore && (
                    <Box sx={{marginTop: '20px', textAlign: 'center'}}>
                      <SecondaryButton onClick={fetchMoreViews} size="small" disabled={loadMoreDisabled}>
                        Load More
                      </SecondaryButton>
                    </Box>
                  )}
                </Stack>
              )}
          </VariableContainer>
        </Stack>
    </Stack>
  )
}

export default function EngagemenView(): React.JSX.Element {
  return (
    <div>
      <Header />
      <Engagement />
      <Footer />
    </div>
  )
}

